export default {
  RELATION_HAS_ACTIVITY: 'hasActivity',
  RELATION_HAS_ACTIVITY_EXECUTION: 'hasActivityExecution',
  RELATION_HAS_CHANNEL: 'hasChannel',
  RELATION_HAS_DATASET: 'hasDataset',
  RELATION_HAS_EXPERIMENT: 'hasExperiment',
  RELATION_HAS_LIFE_ACTIVITY: 'hasLifeActivity',
  RELATION_HAS_MEASURE: 'hasMeasure',
  RELATION_HAS_MODALITY: 'hasModality',
  RELATION_HAS_PARAMETER: 'hasParameter',
  RELATION_HAS_PARTICIPANT: 'hasParticipant',
  RELATION_HAS_RECORDING: 'hasRecording',
  RELATION_HAS_SCENARIO: 'hasScenario',
  RELATION_HAS_SCENARIO_EXECUTION: 'hasScenarioExecution',
};
