export default [
  {
    id: 1,
    name: 'Aktywność 1',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.d.',
    type: 'Individual',
  },
  {
    id: 2,
    name: 'Aktywność 2',
    description: 'Incididunt ut labore.',
    type: 'Two persons activity',
  },
  {
    id: 3,
    name: 'Aktywność 3',
    description: 'Ut enim ad minim veniam.',
    type: 'Group activity',
  },
];
