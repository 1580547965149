export default {
  ACTIVITY: 'A pattern of an activity done within an experiment.',
  ACTIVITY_EXECUTION: 'An execution of a specific activity described by an activity pattern. An activity execution takes place in a certain period of time.',
  APPERANCE: 'An apperance of a participant. This is a concept for which subconcepts can be modelled to define so called user-defined model extension points. To define a model of apperance the subconcepts of this concept should be defined.',
  ARRANGEMENT: 'A placement of participants within group or two persons activities. This is a concept for which subconcepts can be modelled to define so called user-defined model extension points. To define a model of arrangement subconcepts of PersonalGroupArrangement of PersonalTwoPersonsArrangement concepts should be defined.',
  CHANNEL: 'A medium for a registration of a signal holding informatin on observable symptoms.',
  EXPERIMENT: 'A list of activities done by participants in order to gather various biosignals and emotional states for the emotion recognition purpose.',
  GROUP_ACTIVITY: 'A pattern of a group activity done within an experiment. This pattern describes an activity which is performed by three or more participants together.',
  INDIVIDUAL_ACTIVITY: 'A pattern of an individual activity done within an experiment. This pattern describes an activity which is performed by one participant only.',
  LIFE_ACTIVITY: 'Conscious and unconscious actions of a human body, which generate a specified symptom of an emotional state, which can be further analysed in a process of emotion recognition.',
  MEASURE: 'Measured or estimated value which is stored within time series.',
  MODALITY: 'A type of observable information which is used as a proxy for the emotion recognition.',
  PARTICIPANT: 'A person taking part in an experiment.',
  PARTICIPANT_STATE: 'A state of a participant at a specific period of time.',
  PARTICIPATION: 'A participation of a participant within a specific activity execution.',
  RECORDING: 'A recording of a participation of a participant within an activity execution.',
  REGISTERED_CHANNEL: 'A specific channel recorded on registered data.',
  REGISTERED_DATA: 'A registration of signals holding information on observable symptoms.',
  SEX: 'Holds information about a sex of participants.',
  TIME_SERIES: 'Time series for a specific measure.',
  TWO_PERSON_ACTIVITY: 'A pattern of an activity done by two participants together within an experiment. This pattern describes an activity which is performed by exactly two participants together.',
  MEASURE: 'An instance of the Measure concept, it is possible to define the datatype, range and unit modeled as datatype properties measureDatatype, measureRange and measureUnit.',
  DATASET: 'TODO',
};