<template>
  <v-divider
    :vertical="true"
    style="background-color: #f5f5f5"
  />
</template>

<script>
export default {
  name: 'VerticalDivider',
};
</script>
