export default {
  ACTIVITIES: 'activities',
  ACTIVITY_EXECUTIONS: 'activity_executions',
  APPEARANCES: 'appearance',
  ARRANGEMENTS: 'arrangements',
  CHANNELS: 'channels',
  DATASETS: 'datasets',
  EXPERIMENTS: 'experiments',
  LIFE_ACTIVITIES: 'life_activities',
  MEASURES: 'measures',
  MEASURE_NAMES: 'measure_names',
  MODALITIES: 'modalities',
  PARAMETERS: 'parameters',
  PARTICIPANTS: 'participants',
  PARTICIPANT_STATES: 'participant_state',
  PERSONALITIES: 'personality',
  PARTICIPATIONS: 'participations',
  RECORDINGS: 'recordings',
  REGISTERED_DATA: 'registered_data',
  REGISTERED_CHANNELS: 'registered_channels',
  SCENARIOS: 'scenarios',
  SCENARIO_EXECUTIONS: 'scenarioExecutions',
  TIME_SERIES: 'time_series',
  OBSERVABLE_INFORMATIONS: 'observable_information',
};
